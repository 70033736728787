<template>
  <v-card class="ma-3">
    <v-tabs>
      <v-tab @click="tabs = 0">Cronograma Financeiro</v-tab>
      <v-tab
        v-if="
          $user.get().role === 'admin' || $user.get().role === 'consultoria'
        "
        @click="tabs = 1"
        >Dashboard Financeiro</v-tab
      >
    </v-tabs>
    <v-dialog v-model="showNota" style="hidden" scrollable>
      <v-card>
        <v-toolbar color="primary" dark>
          <v-col cols="11.5">
            <span class="headline">Visualização de lançamento</span>
          </v-col>
          <v-btn icon dark @click="showNota = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text outlined>
          <div>
            <LancamentoView v-if="showNota" :nota="nota" />
          </div>
        </v-card-text>
        <v-card-actions style="margin-left: auto">
          <v-btn color="primary" style="margin-left: auto" @click="view()">
            <v-icon style="margin: 6px"> mdi-file-document </v-icon>
            <span style="font-weight: bold; font-size: 16px">
              Visualizar Nota
            </span>
          </v-btn>
          <v-btn color="error" @click="showNota = false">
            <v-icon dark left> mdi-minus-circle </v-icon>
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <base-loading v-if="loading" />
    <div v-else class="content">
      <template>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-card v-if="tabs === 0" style="min-height: 60vh">
              <div class="clearfix" />
              <div>
                <v-form
                  id="form"
                  ref="form"
                  v-model="valid"
                  enctype="multipart/form-data"
                  class="pa-3"
                  lazy-validation
                >
                  <v-card>
                    <template>
                      <v-dialog v-model="dialog" max-width="500px">
                        <v-card>
                          <v-toolbar color="primary" dark>
                            <v-col cols="12">
                              <span style="font-size: 20px">
                                {{ formTitle }}
                              </span>
                            </v-col>
                          </v-toolbar>

                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" md="12" style="padding: 0px">
                                  <v-autocomplete
                                    v-model="editedItem.codPessoaPrestador"
                                    solo
                                    clearable
                                    :items="providers"
                                    :loading="loadingProviders"
                                    item-text="text"
                                    item-value="value"
                                    name="prestadores"
                                    label="Selecione um prestador de serviço*"
                                    :rules="requiredRules"
                                  >
                                  </v-autocomplete>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="4"
                                  style="padding: 0px"
                                >
                                  <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="dateFormatted"
                                        label="Data de envio"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="date"
                                      label="Data de envio"
                                      scrollable
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                      >
                                        CANCELAR
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(date)"
                                      >
                                        OK
                                      </v-btn>
                                    </v-date-picker>
                                  </v-menu>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="5"
                                  style="padding-top: 0px"
                                >
                                  <v-select
                                    v-model="editedItem.month"
                                    :items="meses"
                                    label="Mês de referência"
                                    :rules="requiredRules"
                                  ></v-select>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="3"
                                  style="padding-top: 0px"
                                >
                                  <v-text-field
                                    v-model="editedItem.value"
                                    type="number"
                                    label="Valor da nota (R$)"
                                    :rules="requiredRules"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="12"
                                  style="padding-top: 0px; padding-bottom: 0px"
                                >
                                  <v-text-field
                                    v-model="editedItem.numero"
                                    text
                                    label="Número da nota"
                                    :rules="requiredRules"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="12"
                                  style="padding-top: 0px; padding-bottom: 0px"
                                >
                                  <v-textarea
                                    v-model="editedItem.description"
                                    filled
                                    label="Descrição da nota"
                                    :rules="requiredRules"
                                  >
                                  </v-textarea>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="12"
                                  style="padding-top: 0px; padding-bottom: 0px"
                                >
                                  <span>Upload de arquivo:</span>
                                  <br />
                                  <input
                                    id="inputFile"
                                    ref="inputFile"
                                    type="file"
                                    enctype="multipart/form-data"
                                    @change="onChange"
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions class="justify-end">
                            <v-btn
                              color="success"
                              width="100"
                              :loading="salvando"
                              @click="save"
                            >
                              <v-icon dark left>
                                mdi-checkbox-marked-circle
                              </v-icon>
                              Salvar
                            </v-btn>
                            <v-btn
                              color="error"
                              width="120"
                              :disabled="salvando"
                              @click="close"
                            >
                              <v-icon dark left> mdi-minus-circle </v-icon>
                              Cancelar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-toolbar color="primary" dark>
                          <v-col cols="12">
                            <span style="font-size: 18px">
                              Exclusão de lançamento
                            </span>
                            <br />
                          </v-col>
                        </v-toolbar>
                        <v-card>
                          <v-card-title class="text-h4"
                            >Você tem certeza que deseja excluir esse
                            lançamento?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="success" @click="deleteItemConfirm"
                              >Sim</v-btn
                            >
                            <v-btn color="error" @click="closeDelete"
                              >Não</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>

                    <crud-list
                      v-model="desserts"
                      :headers="headers"
                      :actions="actions"
                      :slots="['item.value']"
                      :search-init="search"
                      :search.sync="search"
                    >
                      <template
                        v-if="
                          $user.get().role === 'gestor' && $user.get().id > 2
                        "
                        v-slot:btnCadastro
                      >
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="dialog = true"
                        >
                          Novo lançamento
                        </v-btn>
                      </template>
                      <template v-slot:[`item.value`]="{ item }">
                        {{ `R$ ${convertNumberBR(item.value)}` }}
                      </template>
                    </crud-list>
                  </v-card>
                </v-form>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <div>
                <Dashboard />
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </div>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import { ENV_APP } from '../../../env'
import { _orderByName } from '../../utils/formatter'
import { convertNumberBR } from '../../utils/masks'
import Dashboard from './Dashboard.vue'
import LancamentoView from './LancamentoView.vue'

export default {
  components: { LancamentoView, Dashboard },
  props: {
    noDataMsg: {
      type: String,
      default: 'Nenhum item encontrado.',
    },
  },
  data() {
    const viewItem = {
      title: 'Visualizar nota',
      color: 'primary',
      click: item => {
        this.codNota = item.codNota
        this.getNote(this.codNota)
      },
      icon: 'mdi-eye',
    }
    const editItem = {
      title: 'Editar nota',
      color: 'orange darken-3',
      click: item => {
        this.editItem(item)
      },
      icon: 'mdi-pencil',
    }
    const deleteItem = {
      title: 'Deletar nota',
      color: 'red darken-3',
      click: item => {
        this.deleteItem(item)
      },
      icon: ' mdi-delete',
    }
    let btnAction = []
    btnAction.push(viewItem, editItem, deleteItem)

    return {
      file: null,
      dialog: false,
      dialogDelete: false,
      actions: btnAction,
      headers: [
        {
          text: 'Prestador',
          value: 'nome',
          align: 'center',
          class: 'primary white--text title',
          width: 'auto',
        },
        {
          text: 'CPF/CNPJ',
          value: 'cpf_cnpj',
          align: 'center',
          class: 'primary white--text title',
          width: '140',
        },
        {
          text: 'Número da nota',
          value: 'numero',
          align: 'center',
          class: 'primary white--text title',
          width: 'auto',
        },
        {
          text: 'Data de envio',
          value: 'data',
          align: 'center',
          class: 'primary white--text title',
          width: 'auto',
        },
        {
          text: 'Valor da nota',
          value: 'value',
          align: 'center',
          class: 'primary white--text title',
          width: '100',
        },
        {
          text: 'Descrição da nota',
          value: 'description',
          sortable: false,
          align: 'left',
          class: 'primary white--text title',
          width: 'auto',
        },
        {
          text: 'Ações',
          value: 'actions',
          sortable: false,
          width: '100',
          align: 'center',
          class: 'primary white--text title',
        },
      ],

      requiredRules: [v => !!v || '* Obrigatório'],

      meses: [
        { text: 'Janeiro', value: 1 },
        { text: 'Fevereiro', value: 2 },
        { text: 'Março', value: 3 },
        { text: 'Abril', value: 4 },
        { text: 'Maio', value: 5 },
        { text: 'Junho', value: 6 },
        { text: 'Julho', value: 7 },
        { text: 'Agosto', value: 8 },
        { text: 'Setembro', value: 9 },
        { text: 'Outubro', value: 10 },
        { text: 'Novembro', value: 11 },
        { text: 'Dezembro', value: 12 },
      ],
      nota: null,
      codNota: null,
      showNota: false,
      desserts: [],
      editedIndex: -1,
      editedItem: {
        codNota: null,
        numero: null,
        file: null,
        codPessoaPrestador: null,
        nome: '',
        cpf_cnpj: null,
        data: this.formatDate(new Date().toISOString().substr(0, 10)),
        month: '',
        value: 0,
        description: '',
      },

      defaultItem: {
        codNota: null,
        file: null,
        numero: null,
        codPessoaPrestador: null,
        nome: '',
        cpf_cnpj: null,
        data: this.formatDate(new Date().toISOString().substr(0, 10)),
        month: '',
        value: 0,
        description: '',
      },
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      menu: false,
      providers: [],
      providersData: [],
      loadingProviders: false,
      data: [],
      select: '',
      edit: false,
      agroindustrias: [],
      salvando: false,
      loading: true,
      loadingEstados: true,
      loadingCidades: false,
      loadingAgroindustrias: true,
      tabs: 0,
      valid: false,
    }
  },

  computed: {
    ...mapState(['relatorio_search']),
    search: {
      get() {
        return null
      },
      set(val) {
        this.$store.commit('SET_RELATORIO_SEARCH', val)
      },
    },
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
    formTitle() {
      return this.editedIndex === -1 ? 'Lançar nova nota' : 'Editar nota'
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(val)
      this.editedItem.data = this.dateFormatted
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.getProviders()
    this.getNotes()
  },
  methods: {
    convertNumberBR,
    async onChange(e) {
      this.file = e.target.files[0]
    },

    view() {
      if (!this.nota.hasfile) {
        this.Swal.fire(
          'Atenção',
          'Nenhum arquivo está associado a esse lançamento!',
          'warning',
        )
      } else {
        const apiUri = ENV_APP.apiUri.slice(0, ENV_APP.apiUri.length - 4)
        window.open(`${apiUri}/storage/notas/${this.codNota}`, '_blank')
      }
    },

    getProviders() {
      this.loadingProviders = true
      this.api.get.prestadores().then(data => {
        this.providers = data
        this.providersData = data
        this.providers = this.providers.map(item => {
          return {
            text: item.Nome + this.typeProvider(item.Tipo) + item.CPF_CNPJ,
            value: item.id,
            ...item,
          }
        })

        this.data = this.providers
        this.providers = _orderByName({
          data: this.providers,
          key: 'text',
        })

        this.loadingProviders = false
      })
    },

    getNotes() {
      this.loading = true
      this.api.get.notas().then(data => {
        this.desserts = data
        this.loading = false
      })
    },

    async getNote() {
      this.api.get.nota(this.codNota).then(data => {
        this.nota = data[0]
        this.showNota = true
      })
    },

    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1)
      const result = this.api.delete.nota(this.editedItem.codNota).then(
        () => {
          return 1
        },
        reject => {
          return reject
        },
      )

      if (result === 1) {
        this.Swal.fire({
          title: 'Sucesso',
          text: 'A nota foi excluída com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 2000,
        })
      }

      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$refs[`inputFile.type`] = 'text'
      this.$refs[`inputFile.type`] = 'file'
      this.file = null
      this.$refs.form.resetValidation()
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$refs[`inputFile.type`] = 'text'
      this.$refs[`inputFile.type`] = 'file'
      this.file = null
      this.$refs.form.resetValidation()
    },

    save() {
      this.valid = this.validate()
      if (!this.valid) {
        this.Swal.fire(
          'Atenção',
          'Preencha todos os itens corretamente.',
          'warning',
        )
        return false
      } else {
        const provider = this.providersData.find(
          provider => provider.id === this.editedItem.codPessoaPrestador,
        )

        this.editedItem.codPessoaPrestador = provider.id
        this.editedItem.nome = provider.Nome
        this.editedItem.cpf_cnpj = provider.CPF_CNPJ
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.updateNote()
      }
    },

    validate() {
      if (
        this.editedItem.codPessoaPrestador &&
        this.editedItem.month &&
        this.editedItem.value &&
        this.editedItem.description
      )
        return true
      return false
    },

    updateNote() {
      let request = this.editedItem
      if (this.file) {
        request = new FormData()
        request.append('file', this.file)
        request.append('nome', this.editedItem.nome)
        request.append('codPessoaPrestador', this.editedItem.codPessoaPrestador)
        request.append('cpf_cnpj', this.editedItem.cpf_cnpj)
        request.append('month', this.editedItem.month)
        request.append('value', this.editedItem.value)
        request.append('description', this.editedItem.description)
        request.append('data', this.editedItem.data)
        request.append('numero', this.editedItem.numero)
      }

      if (this.editedItem.codNota) {
        this.salvando = true
        this.api.update
          .nota(this.editedItem.codNota, request)
          .then(response => {
            this.Swal.fire({
              title: 'Sucesso',
              text: response,
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              this.getNotes()
              this.salvando = false
              this.close()
            })
          })
          .catch(() => {
            this.getNotes()
            this.salvando = false
            this.close()
          })
      } else {
        this.salvando = true

        this.api.save
          .nota(request)
          .then(response => {
            this.Swal.fire({
              title: 'Sucesso',
              text: response,
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              this.getNotes()
              this.salvando = false
              this.close()
            })
          })
          .catch(() => {
            this.getNotes()
            this.salvando = false
            this.close()
          })
      }
    },

    typeProvider(tipo) {
      if (tipo) return ' - CPF: '
      return ' - CNPJ: '
    },
  },
}
</script>
