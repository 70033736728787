<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else>
      <v-card>
        <v-card-text>
          <div class="col-bordered">
            <v-card-title style="text-align: left"
              >INFORMAÇÕES DO PRESTADOR DO SERVIÇO</v-card-title
            >
            <v-row class="pa-3">
              <v-col cols="12" sm="9" lg="9" xl="9" class="col-bordered">
                <span class="key-title">NOME / RAZÃO SOCIAL: </span>
                <span class="value-title">{{ nota.Nome }}</span>
              </v-col>
              <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
                <span class="key-title">CPF / CNPJ: </span>
                <span class="value-title">{{ nota.CPF_CNPJ }}</span>
              </v-col>
              <v-col v-if="nota.CodEndereco" class="col-bordered">
                <span class="value-title">ENDEREÇO: </span>
                <v-row class="pa-3">
                  <v-col cols="6" sm="6" lg="6" xl="6" class="col-bordered">
                    <span class="key-title">CIDADE: </span>
                    <span class="value-title">{{ nota.Cidade }}</span>
                  </v-col>
                  <v-col cols="4" sm="4" lg="4" xl="4" class="col-bordered">
                    <span class="key-title">CEP: </span>
                    <span class="value-title">{{ nota.Cep }}</span>
                  </v-col>
                  <v-col cols="2" sm="2" lg="2" xl="2" class="col-bordered">
                    <span class="key-title">ESTADO: </span>
                    <span class="value-title">{{ nota.Estado }}</span>
                  </v-col>
                  <v-col cols="3" sm="3" lg="3" xl="3" class="col-bordered">
                    <span class="key-title">LOGRADOURO: </span>
                    <span class="value-title">{{ nota.Logradouro }}</span>
                  </v-col>
                  <v-col cols="3" sm="3" lg="3" xl="3" class="col-bordered">
                    <span class="key-title">BAIRRO: </span>
                    <span class="value-title">{{ nota.Bairro }}</span>
                  </v-col>
                  <v-col cols="4" sm="4" lg="4" xl="4" class="col-bordered">
                    <span class="key-title">COMPLEMENTO: </span>
                    <span class="value-title">{{ nota.Complemento }}</span>
                  </v-col>
                  <v-col cols="2" sm="2" lg="2" xl="2" class="col-bordered">
                    <span class="key-title">NÚMERO: </span>
                    <span class="value-title">{{ nota.Numero }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-col class="col-bordered">
              <v-card-title style="text-align: left; padding: 0px"
                >INFORMAÇÕES DA NOTA:</v-card-title
              >
              <v-row class="pa-3">
                <v-col cols="4" sm="4" lg="4" xl="4" class="col-bordered">
                  <span class="key-title">DATA DE ENVIO: </span>
                  <span class="value-title">{{ nota.Data }}</span>
                </v-col>
                <v-col cols="4" sm="4" lg="4" xl="4" class="col-bordered">
                  <span class="key-title">MÊS DE REFERÊNCIA: </span>
                  <span class="value-title">{{
                    checkMes(nota.MesReferencia)
                  }}</span>
                </v-col>
                <v-col cols="4" sm="4" lg="4" xl="4" class="col-bordered">
                  <span class="key-title">VALOR DA NOTA: </span>
                  <span class="value-title">{{
                    `R$ ${convertNumberBR(nota.Valor)}`
                  }}</span>
                </v-col>
                <v-col v-if="nota.Descrição" class="col-bordered">
                  <span class="key-title">DESCRIÇÃO: </span>
                  <v-row>
                    <v-col cols="8" sm="8" lg="8" xl="8">
                      <span class="value-title">{{ nota.Descrição }}</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4" sm="4" lg="4" xl="4" class="col-bordered">
                  <span class="key-title">NÚMERO DA NOTA: </span>
                  <br />
                  <v-row>
                    <v-col cols="8" sm="8" lg="8" xl="8">
                      <span class="value-title">{{ nota.NumeroNota }}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../utils/masks'
export default {
  components: {},

  props: {
    nota: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    if (!this.nota) {
      this.$router.push({
        path: `/${this.$user.get().role}/financeiro`,
      })
    } else {
      this.loading = false
    }
  },
  methods: {
    convertNumberBR,
    checkMes(mes) {
      if (mes == 1) return 'Janeiro'
      if (mes == 2) return 'Fevereiro'
      if (mes == 3) return 'Março'
      if (mes == 4) return 'Abril'
      if (mes == 5) return 'Maio'
      if (mes == 6) return 'Julho'
      if (mes == 7) return 'Julho'
      if (mes == 8) return 'Agosto'
      if (mes == 9) return 'Setembro'
      if (mes == 10) return 'Outubro'
      if (mes == 11) return 'Novembro'
      if (mes == 12) return 'Dezembro'
      else return '-'
    },
  },
}
</script>
<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
