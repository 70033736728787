<template>
  <div>
    <base-loading v-if="loadingFilter" />
    <v-row v-else align="center" justify="center" class="px-10">
      <v-col class="pa-3" cols="2" sm="2" md="2">
        <v-menu
          v-model="menuDataIni"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="moment(dataInicio).format('DD/MM/YYYY')"
              label="Data Inicial"
              prepend-icon="mdi-calendar"
              color="teal"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dataInicio"
            style="margin: 0px"
            :max="dataFim"
            color="teal"
            locale="pt-br"
            @input="menuDataIni = false"
          />
        </v-menu>
      </v-col>
      <v-col class="pa-1" cols="2" sm="2" md="2">
        <v-menu
          v-model="menuDataFim"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="moment(dataFim).format('DD/MM/YYYY')"
              color="teal"
              label="Data Final"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dataFim"
            :min="dataInicio"
            :max="dataLimite"
            color="teal"
            locale="pt-br"
            @input="menuDataFim = false"
          />
        </v-menu>
      </v-col>
      <v-col class="pa-2" cols="2" sm="2" md="2">
        <v-row justify="end" align="center">
          <v-btn
            style="color: white; border-radius: 8px; margin-left: 40%"
            color="green darken-1"
            @click="dashboardReport()"
          >
            <span style="font-size: 16px; font-weight: 600">Buscar</span>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <base-loading v-if="loading" />
    <div v-else>
      <v-row
        v-if="chartDataVisitsMade.datasets.length"
        align="center"
        justify="center"
      >
        <v-col cols="9">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            NOTAS FISCAIS - TOTAL ACUMULADO
          </h2>
          <div class="chart-table">
            <table-graph :items="dataVisitsMade" :headers="headersVisitsMade" />
          </div>
        </v-col>
        <v-col cols="8">
          <div class="chart-table">
            <base-bar-graph
              :chart-data="chartDataVisitsMade"
              :chart-options="chartOptions"
              :style="styles"
            />
          </div>
        </v-col>
      </v-row>

      <v-row v-else align="center" justify="center">
        <h2 class="text-xl-h4 font-weight-medium text--secondary">
          Não há dados para exibir
        </h2>
      </v-row>
    </div>
  </div>
</template>

<script>
import _ from 'underscore'
import TableGraph from '../../components/base/TableGraph.vue'
import {
  assosiationLabel,
  monthShortLabel,
} from '../../utils/dashboard/dashboard'
import { colors } from '../../utils/dashboard/theme'
import { convertNumberBR } from '../../utils/masks'

export default {
  name: 'Dashboard',
  components: {
    TableGraph,
  },

  data() {
    return {
      loading: true,
      loadingFilter: true,

      searching: true,
      dataLimite: this.moment().toISOString(true).substr(0, 10),
      menuDataIni: false,
      menuDataFim: false,
      dataInicio: this.moment()
        .subtract(1, 'months')
        .toISOString(true)
        .substr(0, 10),
      dataFim: this.moment().toISOString(true).substr(0, 10),

      responsiveOptions: [
        [
          'screen and (max-width: 640px)',
          {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0]
              },
            },
          },
        ],
      ],

      styles: {
        backgroundColor: '#f1f1f1',
      },

      headersVisitsMade: [],

      chartDataVisitsMade: {
        labels: monthShortLabel,
        datasets: [],
      },

      optionsCPP1: {
        seriesBarDistance: 10,
      },

      chartOptions: {
        responsive: true,
        scales: {
          yAxes: {
            title: {
              display: true,
              text: 'CCS',
              font: {
                size: 15,
              },
            },
            ticks: {
              precision: 0,
            },
          },
          xAxes: {
            title: {
              display: true,
              text: 'CPP',
              min: 1000,
              font: {
                size: 15,
              },
            },
          },
        },
        plugins: {
          tooltip: {
            // Disable the on-canvas tooltip
            enabled: false,
            position: 'nearest',

            external: context => {
              const { tooltip } = context
              // Tooltip Element
              let tooltipEl = document.getElementById('chartjs-tooltip')

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div')
                tooltipEl.id = 'chartjs-tooltip'
                tooltipEl.innerHTML = '<table></table>'
                document.body.appendChild(tooltipEl)
              }

              // Hide if no tooltip
              const tooltipModel = context.tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = '0'
                return
              }

              // Set caret Position (above, below,no-transform ).As I need above I don't delete that class
              tooltipEl.classList.remove('below', 'no-transform')

              // Set HTML & Data
              if (tooltipModel.body) {
                const innerHtml = `
                        <div style="border-collapse: separate; overflow: hidden; border-radius: 3px; box-shadow: 0 6px 12px rgba(0,0,0,.15); opacity: 1; color: white; transition: all .1s ease; transform: translate(-50%, 0)">
                            <div style="display: flex; padding: 1.2rem; background-color: rgba(0, 0, 0, 0.7)">
                                <div style="display: flex;  flex-direction: column;  font-family: 'Roboto'; font-size: 14px; color: white">
                                    <div style="text-align: center">
                                      <span style="font-weight: bold">${tooltip.dataPoints[0].label}</span>
                                    </div>
                                    <br />
                                    <div>${tooltip.dataPoints[0].dataset.label}: <span style="font-weight: 600">R$${tooltip.dataPoints[0].formattedValue}</span></div>
                                </div>
                            </div>
                         </div>
                    `

                tooltipEl.querySelector('table').innerHTML = innerHtml
              }

              const position = context.chart.canvas.getBoundingClientRect()

              // Display, position, and set styles for font
              tooltipEl.style.opacity = '1'
              tooltipEl.style.position = 'absolute'
              tooltipEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + 'px'
              tooltipEl.style.top =
                position.top + window.pageYOffset + tooltipModel.caretY + 'px'
              tooltipEl.style.padding =
                tooltipModel.padding + 'px ' + tooltipModel.padding + 'px'
              tooltipEl.style.pointerEvents = 'none'
            },
          },
        },
      },
    }
  },

  created() {
    this.dashboardReport()

    Promise.all([]).then(() => {
      this.loadingFilter = false
    })
  },

  methods: {
    dashboardReport() {
      return new Promise((resolve, reject) => {
        this.loading = true
        this.api.get
          .getDashboardFinanceiro(this.dataInicio, this.dataFim)
          .then(response => {
            this.dashboardFinanceiroData = response

            const resultVisitsMade = _.groupBy(
              this.dashboardFinanceiroData,
              item => item.Ano,
            )

            this.headersVisitsMade = [
              { text: '', value: 'Ano' },
              ...Object.keys(resultVisitsMade).map(item => {
                return { text: item, value: item }
              }),
              ...assosiationLabel,
            ]

            this.chartDataVisitsMade.datasets = this.renderDatasetGraph(
              resultVisitsMade,
            )
            const result = this.renderDatasetTable(
              this.chartDataVisitsMade.datasets,
            )

            const resultData = {}

            Object.entries(resultVisitsMade).forEach(([key, value]) => {
              let sum = 0
              value.forEach(item => {
                sum += item.Total
              })
              resultData[key] = `R$ ${convertNumberBR(sum)}`
            })

            result.push({
              Ano: 'Acumulado no ano',
              class: 'blue-row',
              ...resultData,
            })

            this.dataVisitsMade = result

            this.loading = false
            return resolve()
          })
          .catch(error => {
            return reject(error)
          })
      })
    },

    getDashboard() {
      this.loading = true
      this.dashboardReport().catch(() => {
        this.Swal.fire({
          title: 'Erro',
          text: 'Erro ao carregar dados',
          type: 'error',
        }).finally(() => {
          this.loading = false
        })
      })
    },

    renderDatasetGraph(result) {
      let index = 0
      const data = []

      Object.entries(result).forEach(([key, value]) => {
        let tmp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

        value.forEach(item => {
          tmp[item.Mes - 1] = item.Total
        })

        data.push({
          data: tmp,
          label: key,
          backgroundColor: colors[index],
        })

        index++

        tmp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      })
      return data
    },

    renderDatasetTable(result) {
      const data = []

      result.forEach(item => {
        const tmp = {}

        tmp.year = item.label

        tmp.jan = item.data[0] ? `R$ ${convertNumberBR(item.data[0])}` : ' '
        tmp.fev = item.data[1] ? `R$ ${convertNumberBR(item.data[1])}` : ' '
        tmp.mar = item.data[2] ? `R$ ${convertNumberBR(item.data[2])}` : ' '
        tmp.abr = item.data[3] ? `R$ ${convertNumberBR(item.data[3])}` : ' '
        tmp.mai = item.data[4] ? `R$ ${convertNumberBR(item.data[4])}` : ' '
        tmp.jun = item.data[5] ? `R$ ${convertNumberBR(item.data[5])}` : ' '
        tmp.jul = item.data[6] ? `R$ ${convertNumberBR(item.data[6])}` : ' '
        tmp.ago = item.data[7] ? `R$ ${convertNumberBR(item.data[7])}` : ' '
        tmp.set = item.data[8] ? `R$ ${convertNumberBR(item.data[8])}` : ' '
        tmp.out = item.data[9] ? `R$ ${convertNumberBR(item.data[9])}` : ' '
        tmp.nov = item.data[10] ? `R$ ${convertNumberBR(item.data[10])}` : ' '
        tmp.dez = item.data[11] ? `R$ ${convertNumberBR(item.data[11])}` : ' '

        data.push(tmp)
      })

      return data
    },
  },
}
</script>
